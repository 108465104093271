import axios from "axios"
import { useRef, useState } from "react"
import { useEffect } from "react"
import Bounus from "../../elements/bonus/bonus"
import ChatFon from "../../elements/chatFon/ChatFon"
import MailingForm from "../../elements/mailingForm/mailingForm"
import "./social.css"
import Aos from 'aos'
import 'aos/dist/aos.css'
import Case from "../../elements/case/case"
import Benefice from "../../elements/benefice/benefice"
import StatisticCase from "../../elements/statisticKase/statisticCase"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import metas from "../../seo/meta"
import headers from "../../seo/headers"
import texts from "../../seo/text"
import BotCommercial from "../../elements/botCommercial/botCommercial"
import SelfMailing from "../../elements/selfMailing/selfMailing"

import { Helmet } from "react-helmet"

export default function Telegram() {
    const toMailing = useRef('')
    const scroll = useRef('')
    const finger = useRef('')
    const tarifFon = useRef('')
    const intervalRef = useRef('')

    const [scrollpPos, setScrollpPos] = useState(2)
    const [tarifFonScroll, setTarifFonScroll] = useState(null)
    const [openQuestion, setOpenQuestion] = useState(null);

    const toggleQuestion = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    useEffect(() => {
        Aos.init({ duration: 1500 })
        setTarifFonScroll(true)
    }, [])


    useEffect(() => {
        if (tarifFonScroll === true) {
            if (document.body.offsetWidth < 950) {
                intervalRef.current = setInterval(function () {
                    tarifFon.current.scrollTo(0, 0)
                    setTimeout(() => {
                        tarifFon.current.scrollTo(tarifFon.current.scrollWidth, 0)
                    }, 2000);
                }, 5000);
            }
        }
    }, [tarifFonScroll])

    function canselScroll() {
        clearInterval(intervalRef.current)
        finger.current.style.display = 'none'
    }

    let selfDivWidth = 950
    if (document.body.offsetWidth < 1100) {
        selfDivWidth = 810
    }
    if (document.body.offsetWidth < 900) {
        selfDivWidth = 710
    }
    if (document.body.offsetWidth < 700) {
        selfDivWidth = 360
    }

    let positions = []
    let count = 0
    for (let i = 0; i < 5; i++) {
        positions.push(count)
        count += selfDivWidth

    }

    const [price, setPrice] = useState('')
    const [base, setBase] = useState('')
    const [tarif1, setTarif1] = useState('')
    const [tarif1_sale, setTarif1_sale] = useState('')
    const [tarif1_base, setTarif1_base] = useState('')
    const [tarif1_price, setTarif1_price] = useState('')
    const [tarif1_all, setTarif1_all] = useState('')
    const [tarif2, setTarif2] = useState('')
    const [tarif2_sale, setTarif2_sale] = useState('')
    const [tarif2_base, setTarif2_base] = useState('')
    const [tarif2_price, setTarif2_price] = useState('')
    const [tarif2_all, setTarif2_all] = useState('')
    const [tarif3, setTarif3] = useState('')
    const [tarif3_sale, setTarif3_sale] = useState('')
    const [tarif3_base, setTarif3_base] = useState('')
    const [tarif3_price, setTarif3_price] = useState('')
    const [tarif3_all, setTarif3_all] = useState('')
    const [tarif4, setTarif4] = useState('')
    const [tarif4_sale, setTarif4_sale] = useState('')
    const [tarif4_base, setTarif4_base] = useState('')
    const [tarif4_price, setTarif4_price] = useState('')
    const [tarif4_all, setTarif4_all] = useState('')
    const [tarif5, setTarif5] = useState('')
    const [tarif5_sale, setTarif5_sale] = useState('')
    const [tarif5_base, setTarif5_base] = useState('')
    const [tarif5_price, setTarif5_price] = useState('')
    const [tarif5_all, setTarif5_all] = useState('')
    const [tarif6, setTarif6] = useState('')
    const [tarif6_sale, setTarif6_sale] = useState('')
    const [tarif6_base, setTarif6_base] = useState('')
    const [tarif6_price, setTarif6_price] = useState('')
    const [tarif6_all, setTarif6_all] = useState('')

    useEffect(() => {
        axios.get('/api/telegram')
            .then(request => request.data.social)
            .then(request => {
                setPrice(request.price)
                setBase(request.base)
                setTarif1(request.tarif1)
                setTarif1_sale(request.tarif1_sale)
                setTarif1_base(request.tarif1_base)
                setTarif1_price(request.tarif1_price)
                setTarif1_all(request.tarif1_all)
                setTarif2(request.tarif2)
                setTarif2_sale(request.tarif2_sale)
                setTarif2_base(request.tarif2_base)
                setTarif2_price(request.tarif2_price)
                setTarif2_all(request.tarif2_all)
                setTarif3(request.tarif3)
                setTarif3_sale(request.tarif3_sale)
                setTarif3_base(request.tarif3_base)
                setTarif3_price(request.tarif3_price)
                setTarif3_all(request.tarif3_all)
                setTarif4(request.tarif4)
                setTarif4_sale(request.tarif4_sale)
                setTarif4_base(request.tarif4_base)
                setTarif4_price(request.tarif4_price)
                setTarif4_all(request.tarif4_all)
                setTarif5(request.tarif5)
                setTarif5_sale(request.tarif5_sale)
                setTarif5_base(request.tarif5_base)
                setTarif5_price(request.tarif5_price)
                setTarif5_all(request.tarif5_all)
                setTarif6(request.tarif6)
                setTarif6_sale(request.tarif6_sale)
                setTarif6_base(request.tarif6_base)
                setTarif6_price(request.tarif6_price)
                setTarif6_all(request.tarif6_all)
            }
            )
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>
                    {metas.telegram[window.location.hostname].title}
                </title>
                <meta
                    name="description"
                    content={metas.telegram[window.location.hostname].description}
                />
                <meta
                    name="keywords"
                    content={metas.telegram[window.location.hostname].keywords}
                />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ChatFon />
            <div className='homeHeader'>
                <div className="eclipse telegramEclipse"></div>
                <div className="homeHeaderText">
                    <h1 className='hiddetH1Seo'>Рассылка сообщений в Telegram {headers[window.location.hostname]}</h1>
                    <h4>Рассылка сообщений{headers[window.location.hostname]}</h4>
                    <h2>в Telegram от {price}<b>₽</b></h2>
                    <p>Продавайте свой продукт целевой аудитории гораздо быстрее и качественней, <i>каждое сообщение доходит до клиента с уведомлением.</i></p>
                    <div className='homeHeaderButtonDiv'>
                        <a href="#toTarif">
                            <button className='getPresent'>Tарифы</button>
                        </a>
                        <Link to="/#freeConsultation">
                            <button className='getPresent'>Консультация</button>
                        </Link>
                        <div className="headerSocialLinks">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                                <img src="static/img/icons/insta.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                                <img src="static/img/icons/tg.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                                <img src="static/img/icons/waapp.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                                <img src="static/img/icons/vk.png" alt="" />

                            </a>
                        </div>
                    </div>
                    <div className='rates'>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.8 Яндекс</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.9 Google</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Авито</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Wdomain</span>
                        </div>
                    </div>
                </div>
                <div className="homeHeaderImage">
                    <img src="static/img/social/telegram/hand.png" alt="" />
                </div>
            </div>

            <p className='headerSocialText'>Мы тут есть</p>
            <div id='headerSocialLinks' className="headerSocialLinks">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                    <img src="static/img/icons/insta.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                    <img src="static/img/icons/tg.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                    <img src="static/img/icons/waapp.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                    <img src="static/img/icons/vk.png" alt="" />

                </a>
            </div>
            <StatisticCase />
            <div className="socailScreensSlider">
                <h2>5 Вариантов сообщений</h2>
                <Swiper

                    rewind={true}
                    slidesPerView={'3'}
                    centeredSlides={true}
                    spaceBetween={30}
                    loop={true}
                    autoplay={true}
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay, Navigation]}
                    className="socialSwiper"
                >

                    <SwiperSlide>
                        <div className="screenshot">
                            <p>Видео + текст + ссылка</p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №2</span>
                            <img src="static/img/social/telegram/screen2.png" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="screenshot">
                            <p>Текст + ссылка</p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №3</span>
                            <img src="static/img/social/telegram/screen3.png" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="screenshot">
                            <p>Аудио + текст + ссылка</p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №4</span>
                            <img src="static/img/social/telegram/screen4.png" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="screenshot">
                            <p>Текст + кнопка со ссылкой</p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №5</span>
                            <img src="static/img/social/telegram/screen5.png" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="screenshot">
                            <p>Картинка <br /> + текст + ссылка </p>
                            <div className="workLine socialLine"></div>
                            <span>вариант №1</span>
                            <img src="static/img/social/telegram/screen1.png" alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
                <span className="socailScreensSliderspan">Если у вас возникли вопросы по рассылке информации, то вы можете задать их нашему менеджеру в мессенджер или по телефону.</span>
                <p className="socailScreensSliderParagraph">*отправляется только один вариант</p>
            </div>
            <div className="socailScreens">
                <h2>Варианты сообщений</h2>
                <div className="socailScreensSection">
                    <div className="screenshot">
                        <p>Картинка <br /> + текст + ссылка </p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №1</span>
                        <img src="static/img/social/telegram/screen1.png" alt="" />
                    </div>
                    <div className="screenshot">
                        <p>Видео + текст + ссылка</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №2</span>
                        <img src="static/img/social/telegram/screen2.png" alt="" />
                    </div>
                    <div className="screenshot">
                        <p>Текст + ссылка</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №3</span>
                        <img src="static/img/social/telegram/screen3.png" alt="" />
                    </div>
                    <div className="screenshot">
                        <p>Аудио + текст + ссылка</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №4</span>
                        <img src="static/img/social/telegram/screen4.png" alt="" />
                    </div>
                    <div className="screenshot">
                        <p>Текст + кнопка со ссылкой</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №5</span>
                        <img src="static/img/social/telegram/screen5.png" alt="" />
                    </div>
                </div>
                <span>Если у вас возникли вопросы по рассылке информации, то вы можете задать их нашему менеджеру в мессенджер или по телефону.</span>
                <p>*отправляется только один вариант</p>
            </div>
            <Case />
            <Benefice />

            <div className="mailingDiv">
                <div className='workSection socialWorkSection'>
                    <div data-aos="fade-up" className="workImage"><img src="static/img/social/telegram/tg.png" alt="" /></div>
                    <div className="workText">
                        <h5>РАСКАЧАЙТЕ СВОЙ TELEGRAM</h5>
                        <div className='workLine'></div>
                        <p><i>ПРИВЛЕКАЙТЕ БОЛЬШЕ ПОДПИСЧИКОВ</i></p>
                        <span>С помощью рассылки сообщений вы можете быстро и качественно <i>увеличивать количество подписчиков своего канала или группы, увеличивать просмотры и поднять активность.</i> Самый эффективный способ привлечения органической аудитории. А также можете продавать свой продукт напрямую и быстрее.</span>
                    </div>
                </div>
                <div id="toTarif" className="tarifDiv">
                    <h2>тарифы</h2>
                    <p>ОПЛАТА ПРОИЗВОДИТСЯ СТРОГО ПОСЛЕ ПОДТВЕРЖДЕНИЯ ЗАКАЗА МЕНЕДЖЕРОМ</p>
                    <div onTouchStart={canselScroll} onTouchMove={canselScroll} ref={tarifFon} className="tarifFon">
                        <div onTouchStart={canselScroll} onTouchMove={canselScroll} className="tarifs">
                            <div className="tarifInfo">
                                <p>Количество сообщений</p>
                                <span>от {tarif1} шт.</span>
                                <span>от {tarif2} шт.</span>
                                <span>от {tarif3} шт.</span>
                                <span>от {tarif4} шт.</span>
                                <span>от {tarif5} шт.</span>
                                <span>от {tarif6} шт.</span>
                            </div>
                            <div className="tarifLine"></div>
                            <div className="tarifInfo">
                                <p>Стоимость за сообщение</p>
                                <span>{tarif1_price} ₽</span>
                                <span>{tarif2_price} ₽</span>
                                <span>{tarif3_price} ₽</span>
                                <span>{tarif4_price} ₽</span>
                                <span>{tarif5_price} ₽</span>
                                <span>{tarif6_price} ₽</span>
                            </div>
                            <div className="tarifLine"></div>
                            {base !== 'none' ?
                                <>
                                    <div className="tarifInfo">
                                        <p>Стоимость базы <br />(наш сбор {base} ₽)</p>
                                        <span>{tarif1_base} ₽</span>
                                        <span>{tarif2_base} ₽</span>
                                        <span>{tarif3_base} ₽</span>
                                        <span>{tarif4_base} ₽</span>
                                        <span>{tarif5_base} ₽</span>
                                        <span>{tarif6_base} ₽</span>
                                    </div>
                                    <div className="tarifLine"></div>
                                </>
                                :
                                null
                            }

                            <div className="tarifInfo">
                                {base !== 'none' ?
                                    <p>Итоговая стоимость рассылки</p>
                                    :
                                    <p>Стоимость рассылки</p>
                                }
                                <span>{tarif1_all} ₽</span>
                                <span>{tarif2_all} ₽</span>
                                <span>{tarif3_all} ₽</span>
                                <span>{tarif4_all} ₽</span>
                                <span>{tarif5_all} ₽</span>
                                <span>{tarif6_all} ₽</span>
                            </div>
                            <div className="tarifLine"></div>
                            <div className="tarifInfo">
                                <p>Скидка</p>
                                <span>- {tarif1_sale} %</span>
                                <span>- {tarif2_sale} %</span>
                                <span>- {tarif3_sale} %</span>
                                <span>- {tarif4_sale} %</span>
                                <span>- {tarif5_sale} %</span>
                                <span>- {tarif6_sale} %</span>
                            </div>
                        </div>
                        <img ref={finger} className="finger" src="/static/img/finger.png" alt="" />
                        <a href="#mailingFomr">
                            <button>Заказать рассылку</button>
                        </a>
                    </div>
                </div>
                <div className="legs">
                    <Bounus />
                </div>

                <div className='workSection workTextOtchyot'>
                    <div className="workText">
                        <h5>ОТЧЁТНОСТЬ ДО/ПОСЛЕ РАБОТЫ</h5>
                        <div className='workLine'></div>
                        <p>НАМ НЕЧЕГО СКРЫВАТЬ ОТ ВАС</p>
                        <span>
                            Отчетность отправленных сообщений предоставляется в два этапа.
                            <br /><br />
                            <b>Первый</b> - это скрины экрана пк, где мы показываем, что рассылка запущена и производится в штатном режиме.
                            <br /><br />
                            <b>Второй</b> - это скрин экрана пк, где мы показываем, какое количество личных сообщений было успешно отправлено пользователям.
                        </span>
                    </div>
                    <div data-aos="fade-up" className="workImage"><img src="static/img/lists.png" alt="" /></div>
                </div>
                <div id="toMailing"></div>
                <MailingForm platform={'telegram'} />

                <h2 className='faqHeader'>Часто задаваемые вопросы</h2>
                <div className='homeFAQ'>
                    <ul>
                        <li className={`FAQQuestion ${openQuestion === 1 && 'FAQQuestionActive'}`} onClick={() => toggleQuestion(1)}>
                            Могут ли заблокировать ссылку или канал в Telegram рассылке?
                        </li>
                        {openQuestion === 1 && (
                            <p>-Мы используем новейшие методы обхода блокировок, поэтому в нашем случае нет, так как мы максимально контролируем процесс рассылки и когда начинаются массовые жалобы, меняем ссылку на дубль. Также в некоторых рассылках мы используем ссылочную прокладку через внешний или внутренний источник. Тем самым ваша ссылка находится в полной безопасности от блокировки со стороны telegram. Каждая рассылка по-своему уникальна, поэтому определить заранее, как мы будем работать с вашей ссылкой невозможно, это можно будет понять только после того, как полностью будет готов структуризированый текст для рассылки.</p>
                        )}
                    </ul>
                    <ul>
                        <li className={`FAQQuestion ${openQuestion === 2 && 'FAQQuestionActive'}`} onClick={() => toggleQuestion(2)}>
                            Сколько времени нужно для старта telegram рассылки {headers[window.location.hostname]}?
                        </li>
                        {openQuestion === 2 && (
                            <p>- Запуск рассылки зависти от степени подготовки всех этапов, по большей степени время занимает аналитика ниши (поиск групп и чатов для сбора) и сама сборка и фильтрация базы. Все остальное делается во время сборки базы, тем самым мы сокращаем время подготовки.</p>
                        )}
                    </ul>
                    <ul>
                        <li className={`FAQQuestion ${openQuestion === 3 && 'FAQQuestionActive'}`} onClick={() => toggleQuestion(3)}>
                            Что можно отправлять в Telegram?
                        </li>
                        {openQuestion === 3 && (
                            <>
                                <p>- Рассылать можно достаточно много вариантов сообщений, все их виды в визуализации и описании находятся выше на данной странице. Отправлять можно следующее: </p>

                                <p>&nbsp;&nbsp;• Картинка + текст + ссылка </p>
                                <p>&nbsp;&nbsp;• Видео + текст + ссылка</p>
                                <p>&nbsp;&nbsp;• Аудио + текст + ссылка</p>
                                <p>&nbsp;&nbsp;• Пост из канала или чата</p>
                                <p>&nbsp;&nbsp;• Текст + ссылка</p>
                                <p>В текст зачастую указывают одну ссылку на любой ресурс. Также в качестве БОНУСА наш модератор поможет вам с написанием текста для рассылки{headers[window.location.hostname]}, он структуризирует ваше сообщение и сделает его уникальным, тем самым максимально увеличит конверсию. Каждый текст редактируется индивидуально, без каких-либо шаблонов.</p>


                            </>
                        )}
                    </ul>
                    <ul>
                        <li className={`FAQQuestion ${openQuestion === 4 && 'FAQQuestionActive'}`} onClick={() => toggleQuestion(4)}>
                            Как собрать базу для Telegram рассылки?
                        </li>
                        {openQuestion === 4 && (
                            <>
                                <p>- Базу для рассылки сообщений в telegram мы можем собрать из чатов и групп, также собрать можно с каналов тех, кто пишет комментарии под постами. При сборке базу можно отфильтровать по нескольким настройкам: </p>
                                <p>&nbsp;&nbsp;• Активность - когда человек был в сети (недавно, до 3-х дней)</p>
                                <p>&nbsp;&nbsp;• Наличие аватар</p>
                                <p>&nbsp;&nbsp;• Геолокация от группы или чата</p>
                                <p>Все настройки фильтра согласовываются с вами, тем самым мы сможем максимально отсеять ненужные нам аккаунты. После сборки и фильтрации, базу по запросу можем выслать вам в логинах, в дальнейшем вы можете работать с ней по другим задачам.</p>
                            </>
                        )}
                    </ul>
                    <ul>
                        <li className={`FAQQuestion ${openQuestion === 5 && 'FAQQuestionActive'}`} onClick={() => toggleQuestion(5)}>
                            Сколько стоит рассылка сообщений в Telegram?
                        </li>
                        {openQuestion === 5 && (
                            <p>- Стоимость рассылки зависит от двух факторов, стоимости одного сообщения и стоимости сбора и фильтрации базы. Базу для telegram до 5000 сообщений оплачивается в расчёте 0,4 руб за аккаунт, при заказе свыше 5000 сообщений база предоставляется абсолютно бесплатно. Поэтому при формировании заказа обратите внимание на блок «Тарифы» там всё подробно расписано по стоимости отправки и стоимости базы. Если у вас остались вопросы касаемо стоимости рассылки сообщений, то пожалуйста заполните форму «Бесплатная консультация» и мы свяжемся с вами.</p>

                        )}
                    </ul>
                    <ul>
                        <li className={`FAQQuestion ${openQuestion === 6 && 'FAQQuestionActive'}`} onClick={() => toggleQuestion(6)}>
                            Что такое рассылка сообщений в Telegram под ключ?
                        </li>
                        {openQuestion === 6 && (
                            <>
                                <p>- Мы - команда профессионалов с большим опытом работы в сфере лидогенирации через рассылки в Telegram, четко понимаем, что необходимо для рассылки именно в той нише, где находится ваш бизнес. Поэтому мы решили сделать рассылку под ключ. Наша команда подготовит полностью все этапы для запуска рассылки в Telegram. Вам остаётся только согласовывать некоторые этапы работы.</p>
                                <p>Этапы подготовки:</p>
                                <p>&nbsp;&nbsp;• Аналитика вашей ниши</p>
                                <p>&nbsp;&nbsp;• Поиск чатов и групп для сбора</p>
                                <p>&nbsp;&nbsp;• Сбор целевой базы{headers[window.location.hostname]} </p>
                                <p>&nbsp;&nbsp;• Фильтрация целевой базы </p>
                                <p>&nbsp;&nbsp;• Определение структуризации сообщений</p>
                                <p>&nbsp;&nbsp;• Помощь в написании текста для рассылки</p>
                                <p>&nbsp;&nbsp;• Запуск рассылки (с предоставлением отчетов)</p>
                                <p>&nbsp;&nbsp;• Поддержка клиентского отдела на протяжении всей работы</p>

                            </>
                        )}
                    </ul>
                </div>
                <div className='homeFAQ' dangerouslySetInnerHTML={{ __html: texts.telegram[window.location.hostname] }}></div>

            </div>

        </>
    )
}